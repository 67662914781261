/* You can add global styles to this file, and also import other style files */

@import 'tradescout.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.page {
  padding: 48px;
}

// auth

.auth-page {
  @extend .page;
}

.auth-form {
}

.auth-card {
  width: 400px;
  margin: auto;
}

.auth-card-field {
  width: 100%;
}

.auth-card-message {
  margin-top: 48px;
  margin-bottom: 48px;
  text-align: center;
}
